import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import "./src/styles/global.css"

import React from 'react';
import { SearchProvider } from './src/context/search-context';
import { GatsbyBrowser } from 'gatsby';

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
    <SearchProvider>{element}</SearchProvider>
);

const targetPagePath = "/search/"; // Change this to your target page path

export const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = ({ location, prevLocation }) => {
    if (prevLocation && prevLocation.pathname === targetPagePath) {
        sessionStorage.setItem("search-scroll-position", `${window.scrollY}`);
    }
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
    if (location.pathname === targetPagePath) {
        setTimeout(() => {
            window.scrollTo({
                left: 0,
                top: Number(sessionStorage.getItem("search-scroll-position") ?? 0)
            })
        }, 50);
    }
};