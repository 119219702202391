import * as React from "react"
import { useEffect, useState } from "react"
import { styles } from "./header.css"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "../../agnostics"

interface HeaderProps {
    siteTitle: string
    links: Array<{ name: string, path: string }>,
    menuItems?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ siteTitle, links, menuItems }) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (open) document.body.style.overflow = 'hidden'
        else document.body.style.overflow = 'auto'
    }, [open])

    const onMenuIconClick = () => setOpen(() => !open)

    return (<>
        <header className={styles.header}>
            <div className={styles.headerContent}>
                <Link to="/" className={styles.logo} onClick={() => setOpen(false)}>
                    <StaticImage
                        src="../../images/icon.svg"
                        alt={siteTitle}
                        layout="fixed"
                        height={48}
                    />
                    <h1 className={styles.home}>Skedbooks</h1>
                </Link>
                {menuItems}
                <nav className={styles.nav}>
                    <ul className={`${styles.menuList} ${styles.menuListResponsive} `}>
                        {links.map((link) => (
                            <li key={link.name} className={styles.navItem}>
                                <Link to={link.path} onClick={() => setOpen(false)}
                                    className={styles.link} activeClassName={styles.activeLink}>
                                    {link.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className={`${styles.menuIcon} ${styles.menuIconResponsive}`}>
                        <span className={styles.link} onClick={onMenuIconClick}>{!open ? '☰' : '×'}</span>
                    </div>
                </nav>
            </div>
        </header>
        {open && <div className={styles.menuSmall}>
            <div className={styles.menuContainerSmall}>
                <ul className={styles.menuContentSmall}>
                    {links.map((link) => (
                        <li key={link.name} className={styles.navItemSmall}>
                            <Link to={link.path} onClick={() => setOpen(false)}
                                className={styles.link} activeClassName={styles.activeLink}>
                                {link.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>}
    </>
    )
}